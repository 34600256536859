import { graphql } from "gatsby"
import React from "react"

import { ImageTile } from "../../components/career/image-tile/image-tile"
import InitiativeApplication from "../../components/career/initiative-application/initiative-application"
import JobOffersList from "../../components/career/job-offers-list/job-offers-list"
import SEO from "../../components/seo"
import SideNavView from "../../components/views/side-nav-view/side-nav-view"
import { useTranslation } from "../../i18n/translate"
import { dynamicImageWithGatsbyImage } from "../../util/dynamic-image"

const OpenVacanciesPage = ({ data, location, pageContext }) => {
  const { t } = useTranslation()

  const searchParams = new URLSearchParams(location.search)

  return (
    <>
      <SEO
        pathName={location.pathname}
        titleTranslatedString={t("CAREER-OPEN_VACANCIES-META_TITLE")}
        descriptionTranslatedString={t(
          "CAREER-OPEN_VACANCIES-META_DESCRIPTION",
        )}
      />
      <SideNavView
        titleTranslatedString={t("MENU-CAREER-OPEN_VACANCIES_PAGE_TITLE")}
        pathName={location.pathname}
      >
        <div className="_fp-grid _fp-grid--gap">
          <div className="_fp-col-12">
            <ImageTile image={dynamicImageWithGatsbyImage(data.hero)} />
          </div>
          <div className="_fp-col-12">
            <JobOffersList
              lang={pageContext.locale}
              searchParams={searchParams}
            />
          </div>

          <div className="_fp-col-12">
            <InitiativeApplication
              titleTranslatedString={t("CAREER-INITIATIVE_APPLICATION-TITLE")}
              linkPath={"https://munzing.softgarden.io/get-connected"}
              linkTextTranslatedString={t(
                "CAREER-INITIATIVE_APPLICATION-APPLY_NOW",
              )}
              textTranslatedString={t(
                "CAREER-INITIATIVE_APPLICATION-DESCRIPTION",
              )}
            />
          </div>
        </div>
      </SideNavView>
    </>
  )
}

export default OpenVacanciesPage

export const query = graphql`
  {
    hero: strapiDynamicImage(uid: { eq: "CAREER_HERO" }) {
      ...DynamicImageWithGatsbyImage
    }
  }
`
