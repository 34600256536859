/// <reference types="google.maps" />
import {
  Cluster,
  ClusterStats,
  MarkerClusterer,
  Renderer,
} from "@googlemaps/markerclusterer"
import React from "react"
import { withTranslation } from "react-i18next"
import { useDeepCompareEffect } from "react-use"

const getMarkerDefaults = (count: number, t: any) => {
  // change color if this cluster has more markers than the mean cluster
  const color = "#0D6B4E"

  // create svg url with fill color
  const svg = window.btoa(`
          <svg fill="${color}" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 240 240">
            <circle cx="120" cy="120" opacity="1" r="70" />
            <circle cx="120" cy="120" opacity=".5" r="90" />
<!--            <circle cx="120" cy="120" opacity=".2" r="110" />-->
          </svg>`)

  return {
    icon: {
      url: `data:image/svg+xml;base64,${svg}`,
      scaledSize: new google.maps.Size(45, 45),
      fillOpacity: 1,
    },
    title: t("CAREER-JOB_OFFERS-MAP-JOB_COUNT", {
      postProcess: "interval",
      count,
    }),
    opacity: 1,
    label: {
      text: String(count),
      color: "rgba(255,255,255,1)",
      fontSize: "12px",
    },
    zIndex: Number(google.maps.Marker.MAX_ZINDEX) + count,
  }
}

class ClusterRenderer implements Renderer {
  constructor(private t: any) {}
  public render(cluster: Cluster, stats: ClusterStats): google.maps.Marker {
    const { position } = cluster
    const count = cluster.markers.reduce((prev: number, curr) => {
      return prev + (curr["fpAdditionalData"] || 1)
    }, 0)

    // create marker using svg icon
    return new google.maps.Marker({
      position,
      ...getMarkerDefaults(count, this.t),
    })
  }
}

const InternalMarkerCluster = ({
  t,
  map,
  locations,
  setLocations,
  setLocationsOnClick,
  locationsFilter,
  ...options
}) => {
  const [marker, setMarker] = React.useState<MarkerClusterer>()

  useDeepCompareEffect(() => {
    if (map) {
      if (locationsFilter && locationsFilter.length > 0) {
        const bounds = new google.maps.LatLngBounds()
        locationsFilter.forEach((locationFilter) => {
          bounds.extend(locationFilter.location.getLocation())
        })
        map.fitBounds(bounds)
      } else {
        const bounds = new google.maps.LatLngBounds()
        locations.forEach((locationFilter) => {
          bounds.extend(locationFilter.location.getLocation())
        })
        map.fitBounds(bounds)
      }
    }
  }, [locationsFilter])

  React.useEffect(() => {
    if (!marker && map) {
      const markers = locations.map(({ location, count }) => {
        const marker = new google.maps.Marker({
          position: location.location.getLocation(),
          ...getMarkerDefaults(count, t),
        })

        marker["fpAdditionalData"] = count

        // // markers can only be keyboard focusable when they have click listeners
        // // open info window when marker is clicked
        marker.addListener("click", () => {
          setLocations([location])
          setLocationsOnClick([location])
        })

        window.google.maps.event.clearListeners(map, "idle")

        map.addListener("idle", () => {
          const bounds = map.getBounds()
          if (bounds) {
            const locationsInView = locations
              .map(({ location }) => location)
              .filter((location) =>
                bounds.contains(location.location.getLocation()),
              )

            setLocations(locationsInView)
          }
        })

        return marker
      })

      setMarker(
        new MarkerClusterer({
          map,
          markers,
          renderer: new ClusterRenderer(t),
        }),
      )
    }

    // remove marker from map on unmount
    return () => {
      if (marker) {
        marker.onRemove()
      }
    }
  }, [marker, map])

  return null
}

export const MarkerCluster = withTranslation()(InternalMarkerCluster)
