import * as classNames from "classnames"
import React, { useEffect, useRef, useState } from "react"
import { useWindowSize } from "react-use"

import { useTranslation } from "../../i18n/translate"
import ArrowDownIcon from "../../images/icons/arrow_down.svg"
import "./expandable-container.sass"

type ExpandableContainerProps =
  | {
      height?: string
      expanded: boolean
    }
  | {
      expanded: boolean
      showOverlay: boolean
      setExpanded?: (expanded: boolean) => any
    }

export const ExpandableContainer = ({
  height,
  expanded = false,
  setExpanded,
  showOverlay,
  children,
  showCollapseOverlay = false,
  overlayTextTranslatedString,
}: ExpandableContainerProps) => {
  const itemsRef = useRef()

  useWindowSize()

  const { t } = useTranslation()

  let [internalExpanded, setInternalExpanded] = useState(expanded)
  if (setExpanded) {
    internalExpanded = expanded
    setInternalExpanded = setExpanded
  }

  let expandedHeight: number = 0
  if (itemsRef.current) {
    expandedHeight = itemsRef.current.clientHeight
  }

  useEffect(() => {
    if (window["reactUseSyncedScrollUpdateLayout"]) {
      window["reactUseSyncedScrollUpdateLayout"]()
    }
    setTimeout(() => {
      if (window["reactUseSyncedScrollUpdateLayout"]) {
        window["reactUseSyncedScrollUpdateLayout"]()
      }
    }, 100)
  })

  return (
    <div
      className={"_fp-expandable-container"}
      style={{
        height: internalExpanded
          ? `${expandedHeight}px`
          : showOverlay
          ? height ?? "200px"
          : height ?? 0,
      }}
    >
      <div
        className={"_fp-expandable-container__inner-container"}
        ref={itemsRef}
      >
        {children}

        {showCollapseOverlay && (
          <button
            className={classNames("_fp-expandable-container__collapse", {
              "_fp-expandable-container__collapse--hidden": !internalExpanded,
            })}
            onClick={() => setInternalExpanded(false)}
          >
            <div className="_fp-expandable-container__collapse__button">
              <ArrowDownIcon />{" "}
              <p className="_fp-text">{t("GLOBAL-SHOW_LESS")}</p>
            </div>
          </button>
        )}
      </div>
      {!internalExpanded && (
        <button
          className={"_fp-expandable-container__overlay"}
          onClick={() => setInternalExpanded(true)}
        >
          <div className="_fp-expandable-container__overlay__button">
            <ArrowDownIcon />{" "}
            <p className="_fp-text">
              {overlayTextTranslatedString || t("GLOBAL-READ_MORE")}
            </p>
          </div>
        </button>
      )}
    </div>
  )
}
