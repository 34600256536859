import PropTypes from "prop-types"
import React from "react"

import LocalizedLink from "../../../i18n/localized-link"
import { withTranslation } from "../../../i18n/translate"
import { SoftgardenJobModel } from "../../../model/career/softgardenJobModel"
import "./job-offer-preview.sass"

class JobOfferPreview extends React.Component {
  render() {
    const { t, i18n } = this.props
    const jobOffer = this.props.jobOffer

    return (
      <div className={"_fp-job-offer-preview"}>
        <LocalizedLink tabIndex={-1} to={jobOffer.getPageUrl()}>
          <p className={"_fp-job-offer-preview__title _fp-heading-4"}>
            {jobOffer.externalPostingName}
          </p>
        </LocalizedLink>
        <p className={"_fp-text _fp-text--small _fp-job-offer-preview__info"}>
          {jobOffer.locations?.length > 0 && (
            <span>{t(jobOffer.locations[0].name.key)}</span>
          )}

          {jobOffer.getJobFeaturesStrings(i18n.language).map((str, i) => (
            <span key={`job-feature-${i}`}>
              <span className={"_fp-job-offer-preview__info__saparator"}>
                —
              </span>
              <span>{str}</span>
            </span>
          ))}
        </p>
      </div>
    )
  }
}

JobOfferPreview.propTypes = {
  jobOffer: PropTypes.instanceOf(SoftgardenJobModel).isRequired,
}

JobOfferPreview.defaultProps = {}

export default withTranslation()(JobOfferPreview)
