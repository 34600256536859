import PropTypes from "prop-types"
import React from "react"

import { useTranslation } from "../../i18n/translate"
import "./button-group.sass"

const ButtonGroup = ({ labelTranslationKey, children }) => {
  const { t } = useTranslation()
  return (
    <div className="_fp-button-group">
      <div className="_fp-button-group__label">
        <p className={"_fp-text"}>{t(labelTranslationKey)}</p>
      </div>
      <div className="_fp-button-group__buttons">{children}</div>
    </div>
  )
}

ButtonGroup.propTypes = {
  labelTranslationKey: PropTypes.string,
  children: PropTypes.node.isRequired,
}

export default ButtonGroup
