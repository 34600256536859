import { find } from "lodash"

export class GoogleMapsLocationModel {
  constructor(args = {}) {
    for (const key in args) {
      this[key] = args[key]
    }
  }

  getPlaceId() {
    return this.place_id || ""
  }

  getLocation() {
    return this.geometry && this.geometry.location ? this.geometry.location : {}
  }

  getFormattedPlaceName() {
    const c = find(this.address_components, function (o) {
      return o.types.includes("country")
    })
    const l = find(this.address_components, function (o) {
      return o.types.includes("locality")
    })
    return (l ? `${l.long_name}, ` : "") + c ? c.long_name : ""
  }

  getFormattedAddress() {
    return this.formatted_address || ""
  }
}
