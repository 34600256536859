import { graphql, useStaticQuery } from "gatsby"

import { SoftgardenJobModel } from "../model/career/softgardenJobModel"
import { MunzingLocationModel } from "../model/munzingLocationModel"

const useJobOffers = () => {
  const data = useStaticQuery(graphql`
    query JobOffersQuery {
      allSoftgardenJob {
        nodes {
          ...SoftgardenJobParams
        }
      }
      allStrapiMunzingLocations {
        edges {
          node {
            strapiId
            name {
              key
            }
            location {
              ...GoogleMapsLocationParams
            }
          }
        }
      }
    }
  `)

  const locations = data.allStrapiMunzingLocations.edges.map(
    ({ node }) => new MunzingLocationModel(node),
  )

  return data.allSoftgardenJob.nodes
    .map((jobOffer) => {
      return new SoftgardenJobModel(jobOffer, locations)
    })
    .sort((a, b) => a.externalPostingName.localeCompare(b.externalPostingName))
}

export default useJobOffers
