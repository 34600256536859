/// <reference types="google.maps" />
import { Wrapper } from "@googlemaps/react-wrapper"
import classNames from "classnames"
import PropTypes from "prop-types"
import React, { useEffect } from "react"
import { useDeepCompareEffect } from "react-use"

import { MarkerCluster } from "./markerCluster"
import "./openVacanciesMap.sass"

const defaultLocation = {
  lat: 49.0719153,
  lng: 9.2858928,
}

const mapStyle = require("./mapStyle.json")

const Map = ({ children, style, ...options }) => {
  const ref = React.useRef(null)
  const [map, setMap] = React.useState()

  useDeepCompareEffect(() => {
    if (map) {
      map.setOptions(options)
    }
  }, [map, options])

  React.useEffect(() => {
    if (ref.current && !map) {
      setMap(new window.google.maps.Map(ref.current, {}))
    }
  }, [ref, map])

  return (
    <>
      <div ref={ref} style={style} />
      {React.Children.map(children, (child) => {
        if (React.isValidElement(child)) {
          // set the map prop on the child component
          // @ts-ignore
          return React.cloneElement(child, { map })
        }
      })}
    </>
  )
}

const OpenVacanciesMap = ({
  jobOffers,
  setLocations,
  setLocationsOnClick,
  locationsFilter,
}) => {
  const jobOfferCountByLocation = {}

  jobOffers.forEach((jobOffer) => {
    ;(jobOffer.locations || []).forEach((location) => {
      const { lat, lng } = location.location.getLocation()
      const hash = `${(Math.round(lat * 1000000), Math.round(lng * 1000000))}`
      let count = 1
      if (jobOfferCountByLocation[hash]) {
        count += jobOfferCountByLocation[hash].count
      }

      jobOfferCountByLocation[hash] = { jobOffer, location, count }
    })
  })

  const locations = Object.values(jobOfferCountByLocation)

  const [zoom, setZoom] = React.useState(3) // initial zoom
  const [center, setCenter] = React.useState(defaultLocation)

  const render = (status) => {
    return <div></div>
  }

  return (
    <div className={classNames("_fp-munzing-open-vacancies-google-maps-map")}>
      <Wrapper
        apiKey={"AIzaSyD3utzJ6KypMIKU2XOzve3KxJ-H7Q-PkIg"}
        render={render}
      >
        <Map
          center={center}
          zoom={zoom}
          style={{ flexGrow: "1", height: "100%" }}
          maxZoom={15}
          disableDefaultUI={true}
        >
          <MarkerCluster
            locations={locations}
            setLocations={setLocations}
            setLocationsOnClick={setLocationsOnClick}
            locationsFilter={locationsFilter}
          />
        </Map>
      </Wrapper>
    </div>
  )
}

OpenVacanciesMap.propTypes = {
  jobOffers: PropTypes.array.isRequired,
  useCustomMap: PropTypes.bool,
  handleClick: PropTypes.array,
}

export default OpenVacanciesMap
